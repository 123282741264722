$( document ).ready(function() {
    // Füge Abstand hinzu wenn Content-Box eine Headline-Box beinhaltet
    $('.content-box-wrapper .content-box-innerwrapper .headline-box').closest('.content-box-wrapper').addClass('has-headline-box');
    $('.content-box-wrapper .content-image').closest('.content-box-wrapper').addClass('has-content-image');

    // Off-Canvas
    $( ".nav-toggle-open" ).click(function() {
        $( "#off-canvas" ).addClass( 'active' );
        $( "#page-wrapper" ).addClass( 'off-canvas-active' );
        $( "#header-main" ).addClass( 'off-canvas-active' );
        $( "#footer-main" ).addClass( 'off-canvas-active' );
    });

    $( ".menu_icon_open_start" ).click(function() {
        $( "#off-canvas" ).addClass( 'active' );
        $( "#page-wrapper" ).addClass( 'off-canvas-active' );
        $( "#header-main" ).addClass( 'off-canvas-active' );
        $( "#footer-main" ).addClass( 'off-canvas-active' );
    });

    $( ".nav-toggle-close" ).click(function() {
        $( "#off-canvas" ).removeClass( 'active' );
        $( "#page-wrapper" ).removeClass( 'off-canvas-active' );
        $( "#header-main" ).removeClass( 'off-canvas-active' );
        $( "#footer-main" ).removeClass( 'off-canvas-active' );
    });

    $(document).keyup(function(e) {
        if (e.keyCode == 27) { // esc keycode
            $( "#off-canvas" ).removeClass( 'active' );
            $( "#page-wrapper" ).removeClass( 'off-canvas-active' );
            $( "#header-main" ).removeClass( 'off-canvas-active' );
            $( "#footer-main" ).removeClass( 'off-canvas-active' );
        }
    });

    // Back-to-top
    $('.footer-back-to-top').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 500);
        return false;
    });

    $('.slider-col1').each(function (idx, item) {
        var carouselId = "carousel" + idx;
        this.id = carouselId;
        $(this).slick({
            //slide: "#" + carouselId +" .option",
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '.slider-caption-col1#'+carouselId
        });
    });

    $('.slider-caption-col1').each(function (idx, item) {
        var carouselId = "carousel" + idx;
        this.id = carouselId;
        $(this).slick({
            //slide: "#" + carouselId +" .option",
            arrows: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '.slider-col1#'+carouselId
        });
    });

    $('.slider-col2').each(function (idx, item) {
        var carouselId = "carousel" + idx;
        this.id = carouselId;
        $(this).slick({
            //slide: "#" + carouselId +" .option",
            infinite: true,
            speed: 300,
            slidesToShow: 2,
            slidesToScroll: 2,
            asNavFor: '.slider-caption-col2#'+carouselId,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    });

    $('.slider-caption-col2').each(function (idx, item) {
        var carouselId = "carousel" + idx;
        this.id = carouselId;
        $(this).slick({
            //slide: "#" + carouselId +" .option",
            arrows: false,
            infinite: true,
            speed: 300,
            slidesToShow: 2,
            slidesToScroll: 2,
            asNavFor: '.slider-col2#'+carouselId,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    });

    $('.slider-col3').each(function (idx, item) {
        var carouselId = "carousel" + idx;
        this.id = carouselId;
        $(this).slick({
            //slide: "#" + carouselId +" .option",
            infinite: true,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 3,
            asNavFor: '.slider-caption-col3#'+carouselId,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 639,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    });

    $('.slider-caption-col3').each(function (idx, item) {
        var carouselId = "carousel" + idx;
        this.id = carouselId;
        $(this).slick({
            //slide: "#" + carouselId +" .option",
            arrows: false,
            infinite: true,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 3,
            asNavFor: '.slider-col3#'+carouselId,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 639,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    });

    $('.slider-col4').each(function (idx, item) {
        var carouselId = "carousel" + idx;
        this.id = carouselId;
        $(this).slick({
            //slide: "#" + carouselId +" .option",
            infinite: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 4,
            asNavFor: '.slider-caption-col4#'+carouselId,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 639,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    });

    $('.slider-caption-col4').each(function (idx, item) {
        var carouselId = "carousel" + idx;
        this.id = carouselId;
        $(this).slick({
            //slide: "#" + carouselId +" .option",
            arrows: false,
            infinite: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 4,
            asNavFor: '.slider-col4#'+carouselId,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 639,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    });

    // Rotate-Box
    $( ".rotate-box-wrapper .content-front, .rotate-box-wrapper .content-back" ).click(function() {
        $(window).trigger('resize');
        $(this).closest(".rotate-box-wrapper").find(".content-front").toggleClass( 'active' );
        $(this).closest(".rotate-box-wrapper").find(".content-back").toggleClass( 'active' );
    });

    // Accordion
    $( ".accordion-wrapper.facts .accordion-trigger" ).click(function() {
        $(window).trigger('resize');
        $(this).closest(".accordion-wrapper.facts").find(".accordion-content").toggleClass( 'active' );
        $(this).closest(".accordion-wrapper.facts").find(".accordion-trigger").toggleClass( 'active' );
    });

    // Accordion
    $( ".accordion-wrapper.read-more .accordion-trigger" ).click(function() {
        $(window).trigger('resize');
        $(this).closest(".accordion-wrapper.read-more").find(".accordion-content").toggleClass( 'active' );
        $(this).closest(".accordion-wrapper.read-more").find(".accordion-trigger").toggleClass( 'active' );
    });

    // Accordion
    $( "li.accordion-std-item" ).click(function() {
        $(this).children().toggleClass("active");
    });

    // Select Desktop
    $( ".select-desktop li" ).click(function() {
        $(this).closest(".select-desktop").find("li:first-child").toggleClass( 'active' );
    });

    $( ".acc-item" ).click(function() {
        currentID = $(this).attr("id");

        $("li.acc-item").each(function(index) {
            if( $(this).attr("id")==currentID) {
                isOpen =  $(this).attr( "is-open");
                if (isOpen=="true") {
                    $(this).children().removeClass("active");
                    $(this).attr( "is-open", "false" );
                    $(this).find(".accordion-list-content").css("z-index", "0")
                } else{
                    $(this).attr( "is-open", "true" );
                    $(this).children().addClass("active");
                    $(this).find(".accordion-list-content").css("width", $("li.acc-item").parent().width());
                    $(this).find(".accordion-list-content").css("left", -$(this).position().left)
                    $(this).find(".accordion-list-content").css("z-index", "99")
                }
            } else {
                $(this).children().removeClass("active");
                $(this).attr( "is-open", "false" );
                $(this).find(".accordion-list-content").css("z-index", "0")
            }
        });
    });

    $( window ).resize(function() {
        $("li.acc-item").each(function(index) {
            isOpen =  $(this).attr( "is-open");
            if (isOpen=="true") {
                $(this).find(".accordion-list-content").css("width", $("li.acc-item").parent().width());
                $(this).find(".accordion-list-content").css("left", -$(this).position().left)
            }
        });
    });
});

var waypoint = new Waypoint({
    element: $('#header-main'),
    handler: function(direction) {
    },
    offset: 'bottom-in-view'
});

var $header = $('#header-main'),
    $main = $('#main-content');
    $winfoo = $(window),
    winHfoo = $winfoo.height();

$winfoo.on("scroll", function () {
    $header.toggleClass("show", $(this).scrollTop() > 300 );
    $main.toggleClass("sticky-button-scroll", $(this).scrollTop() > 150 );
}).on("resize", function(){
    winHfoo = $(this).height();
});